import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Button, Container } from 'react-bootstrap';
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import Img from 'gatsby-image';
import parse from 'html-react-parser';
import loadable from '@loadable/component'
import _ from "lodash"
import $ from 'jquery'
import { navigate, useMatch } from "@reach/router"
import SearchResultInputBox from "../../predictive-search/SearchResultBoxHeader"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../../utils/property-search"
// import GoogleRating from "../../googleRating/GoogleRating"
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
// 
import GenerateLink from "../../common/site/generate-link"
// import TrustPilotWidget from "../../TrustPilotWidget"

import Trustpilot from "../../../images/trustpilot.png"
import GoogleReviews from "../../../images/google-reviews.png"
import BannerVideo from "../../../images/home-video/wards-home-video.mp4"
import Close from "../../../images/home-close.svg"
import GoogleReviewCount from "../../GoogleReviewCount/GoogleReviewCount"
import { isTablet, isMobile, isDesktop } from "react-device-detect";

// import { inViewOptions, contentItemStagger, contentItem, fadeInFromTop } from '../../utils/animation';

// import { motion } from "framer-motion";
// import { InView } from "react-intersection-observer";


// styles
// import ScrollAnimation from 'react-animate-on-scroll';
import "./Banner.scss"
// markup

const LoqateAddress = loadable(() => import('../../forms/elements/address-search-instant'))
const TrustPilotWidget = loadable(() => import('../../TrustPilotWidget'))

const HomeBanner = (props) => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
        areaGuides(
            publicationState: LIVE
            where: {Publish: true}
          ) {
            id
            Name
            Banner_Image {
              url
              alternativeText 
            }
            imagetransforms
        }

    }
  }
`);

  const areaguides = data.glstrapi.areaGuides;



  const [bannercta, setBannerCTA] = useState(true);
  const [areaimages, setAreaImages] = useState('');
  const [bannerimageVal, setBannerimageVal] = useState('');
  const [valuation, setValuation] = useState(false);
  const [propertysearch, setPropertySearch] = useState(false);
  const [areaVal, setAreaVal] = useState('')
  const queryList = QueryList();

  function OpenValuation() {
    setBannerCTA(false)
    setValuation(true)
    setPropertySearch(false)

  }

  function OpenPropertySearch() {
    setBannerCTA(false)
    setValuation(false)
    setPropertySearch(true)

  }

  function CloseSearch() {
    setBannerCTA(true)
    setValuation(false)
    setPropertySearch(false)

  }


  // Valuation
  function onClickBuy(e) {
    var address = $("input[name=in_address]").val();
    var postcode = $("input[name=in_postcode]").val();
    var in_address_home = $("input[name=in_address_home]").val();

    var instant_stno = $("input[name=instant_stno]").val();
    var instant_street = $("input[name=instant_street]").val();
    var instant_district = $("input[name=instant_district]").val();
    var instant_post_town = $("input[name=instant_post_town]").val();
    var instant_county = $("input[name=instant_county]").val();
    var instant_postcode = $("input[name=instant_postcode]").val();
    var hometrack_add = $("input[name=hometrack_add]").val();

    localStorage.setItem('instant-address-full', address)
    localStorage.setItem('instant-address', in_address_home)
    localStorage.setItem('instant-postcode', postcode)

    localStorage.setItem('instant-stno', instant_stno)
    localStorage.setItem('instant-street', instant_street)
    localStorage.setItem('instant-district', instant_district)
    localStorage.setItem('instant-post_town', instant_post_town)
    localStorage.setItem('instant-county', instant_county)
    localStorage.setItem('instant-postcode', instant_postcode)
    localStorage.setItem('hometrack_add', hometrack_add)

    navigate(`/sell-your-property/property-valuation/instant-valuation`)
  }

  function onClickRent(e) {
    var address = $("input[name=in_address]").val();
    var postcode = $("input[name=in_postcode]").val();
    var in_address_home = $("input[name=in_address_home]").val();
    var instant_stno = $("input[name=instant_stno]").val();
    var instant_street = $("input[name=instant_street]").val();
    var instant_district = $("input[name=instant_district]").val();
    var instant_post_town = $("input[name=instant_post_town]").val();
    var instant_county = $("input[name=instant_county]").val();
    var instant_postcode = $("input[name=instant_postcode]").val();
    var hometrack_add = $("input[name=hometrack_add]").val();
    localStorage.setItem('instant-address-full', address)
    localStorage.setItem('instant-address', in_address_home)
    localStorage.setItem('instant-postcode', postcode)
    localStorage.setItem('instant-stno', instant_stno)
    localStorage.setItem('instant-street', instant_street)
    localStorage.setItem('instant-district', instant_district)
    localStorage.setItem('instant-post_town', instant_post_town)
    localStorage.setItem('instant-county', instant_county)
    localStorage.setItem('instant-postcode', instant_postcode)
    localStorage.setItem('hometrack_add', hometrack_add)
    navigate(`/sell-your-property/property-valuation/instant-valuation`)
  }
  // Valuation


  // Property Search 

  function onClickPropertyBuy(val) {
    let url = "/property/for-sale/";
    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase()

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      url += "in-kent/"
    }

    localStorage.setItem('searchValue', searcValue ? searcValue : 'kent');
    localStorage.setItem('searchValueFull', searcValue ? searcValue : 'kent');
    localStorage.setItem('searchValueFullName', val ? val : 'kent');
    navigate(url);
    // window.location.href = url;
  }
  function onClickPropertyRent(val) {
    let url = "/property/to-rent/";
    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase()

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      url += "in-kent/"
    }

    localStorage.setItem('searchValue', searcValue ? searcValue : 'kent');
    localStorage.setItem('searchValueFull', searcValue ? searcValue : 'kent');
    localStorage.setItem('searchValueFullName', val ? val : 'kent');

    navigate(url);
    // window.location.href = url;
  }

  // Property Search


  useEffect(() => {
    if (areaguides.length > 0) {
      setAreaImages(_.shuffle(areaguides))
    }
    setBannerCTA(true)
    setValuation(false)
    setPropertySearch(false)

    if (sessionStorage.getItem('home_banner_img')) {
      setBannerimageVal(sessionStorage.getItem('home_banner_img'))
    }
    setTimeout(() => {
      sessionStorage.setItem('home_banner_img', "ggfx")
    }, 2000);
  }, []);

  let banner_width = "770"
  let banner_height = "700"
  if (isMobile) {
    banner_width = "376"
    banner_height = "600"
  }
  if (isDesktop) {
    banner_width = "1800"
    banner_height = "1055"
  }
  return (

    <React.Fragment>
      <div className="main-banner components--Home--Banner-Banner">
        <div className="main-banner-embed">
          {/* <video width="100%" height="100%" autoPlay playsInline loop muted className="video-banner">
            <source src={BannerVideo} type="video/mp4" />
          </video> */}
          {bannerimageVal !== "ggfx" ? <StaticImage src="../../../images/area-guide/home-banner.jpg" layout="fullWidth" placeholder="blurred" formats={["webp"]} alt="banner" quality="65" className="img-fluid banner_img" /> :

            <>
              {areaimages && areaimages.slice(0, 1).map((item, index) => {

                const image_url = item.Banner_Image.url;

                let processedImages = JSON.stringify({});
                if (item.imagetransforms?.Banner_Image_Transforms) {
                  processedImages = item.imagetransforms.Banner_Image_Transforms;
                }

                return (
                  <ImageTransform
                    imagesources={item.Banner_Image.url}
                    renderer="srcSet"
                    imagename="area-guides.Banner_Image.details"
                    attr={{ alt: '', class: '', width: `${banner_width}`, height: `${banner_height}` }}
                    imagetransformresult={processedImages}
                    id={item.id}
                  />
                )
              })}
            </>
          }
          {/* {areaimages[0] &&
            <ImageTransform
              imagesources={areaimages[0].Banner_Image.url}
              renderer="srcSet"
              imagename="area-guides.Banner_Image.details"
              attr={{ alt: '', class: '' }}
              imagetransformresult={processedImages}
              id={areaimages[0].id}
            />
            // <Img fluid={areaimages[0].Banner_Image.url_sharp.childImageSharp.fluid} alt={areaimages[0].Banner_Image.alternativeText} />
          } */}

          {/* {props.Banner_Image &&
        <Img fluid={props.Banner_Image.url_sharp.childImageSharp.fluid} alt={props.Banner_Image.alternativeText} />
      } */}
        </div>
        <div className="main-banner-content">
          <Container>
            <div>
              <div className="banner-text">

                <div className="banner-title">
                  {parse(props.Banner_Content)}
                </div>

                <div className="home-banner-search" id="home-banner-search">
                  {bannercta &&
                    <div className="btn-wrap" id="home-banner-search-cta">
                      <div className="btn-width-cta">
                        <a href="javascript:void(0)" class="btn btn-secondary" onClick={() => OpenValuation()}>
                          {props.Banner_CTA_1_Label_Heading && <span>{props.Banner_CTA_1_Label_Heading}</span>}
                          {props.Banner_CTA_1_Label}
                        </a>
                      </div>
                      {props.Banner_CTA_2_Label &&
                        <div className="btn-width-cta">
                          <a href="javascript:void(0)" class="btn" onClick={() => OpenPropertySearch()}>
                            {props.Banner_CTA_2_Label_Heading && <span>{props.Banner_CTA_2_Label_Heading}</span>}
                            {props.Banner_CTA_2_Label}
                          </a>
                        </div>
                      }

                    </div>
                  }
                  {valuation &&
                    <div className="home-banner-search-valuation" id="home-banner-search-valuation">
                      <LoqateAddress />
                      <div className="btn-wrap">
                        <button class="btn btn-secondary" onClick={() => onClickBuy()}>
                          <span>I want to</span>Sell
                        </button>
                        <button class="btn btn-primary" onClick={() => onClickRent()}>
                          <span>I want to</span>Let
                        </button>
                        <img src={Close} className="close" alt="" onClick={() => CloseSearch()} />
                      </div>
                    </div>
                  }
                  {propertysearch &&
                    <div className="home-banner-search-property" id="home-banner-search-property">
                      <div class="text form-group form-group">
                        <div class="custom-float">
                          <SearchResultInputBox
                            value={areaVal !== 'kent' ? hyphenToCaptilize(areaVal) : ''}
                            setValue={(searchValue) => {
                              // refine(removeSpecialChar(searchValue))
                              setAreaVal(searchValue)
                            }}
                            placeHolder={`Choose town or area`}
                            areaList={queryList.areaList}
                            additionalclass="address address-search"
                          />
                        </div>
                      </div>
                      <div className="btn-wrap">
                        <button class="btn btn-secondary" onClick={() => onClickPropertyBuy(areaVal)}>
                          <span>I’d like to</span>Buy
                        </button>
                        <button class="btn" onClick={() => onClickPropertyRent(areaVal)}>
                          <span>I’d like to</span>Rent
                        </button>
                        <img src={Close} alt="" onClick={() => CloseSearch()} />
                      </div>

                    </div>
                  }
                </div>
              </div>
            </div>


            {props.Show_Reviews &&
              <div>
                <div className="rating-block">
                  <div className="trustpilot-review">
                    <TrustPilotWidget />
                  </div>

                  <div className="land-banner-review">
                    <div className="google-review">
                      <GoogleReviewCount />
                      {/* <div className="review-logo">
                        <img src={GoogleReviews} alt="" width="74" />
                      </div>
                      <p>
                        <strong>4.5 out of 5 </strong>
                        based on 64 reviews
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            }




          </Container>
        </div>


      </div>
    </React.Fragment>
  )
}

export default HomeBanner
